import plugin from 'tailwindcss/plugin.js';

export default {
    theme: {
        fontFamily: {
            sans: ['Expressway', 'sans-serif'],
        },
        extend: {
            colors: {
                primary: {
                    DEFAULT: '#26a9e0',
                    50: '#e9f6fc',
                    100: '#d4eef9',
                    200: '#a8ddf3',
                    300: '#7dcbec',
                    350: '#78cbe4',
                    400: '#51bae6',
                    500: '#26a9e0',
                    600: '#1e87b3',
                    700: '#1b769d',
                    800: '#135570',
                    900: '#0b3343',
                },
                secondary: {
                    DEFAULT: '#45c990',
                    50: '#ecfaf4',
                    100: '#daf4e9',
                    200: '#b5e9d3',
                    300: '#8fdfbc',
                    400: '#6ad4a6',
                    500: '#45c990',
                    600: '#37a173',
                    700: '#297956',
                    800: '#1c503a',
                    900: '#0e281d',
                },
                tertiary: {
                    DEFAULT: '#34b4f5',
                    50: '#293f4d',
                },
                light: '#f8f9fa',
                body: { DEFAULT: '#495057' },
                gray: {
                    50: '#f8f9fa',
                    800: '#343a40',
                },
                stars: {
                    900: '#EDBD40',
                    0: '#AEBFD1',
                },
                error: {
                    DEFAULT: '#FF3738',
                },
                pink: {
                    DEFAULT: '#e62571',
                    border: '#e93f82',
                    hover: '#c11659',
                    borderHR: '#b41658',
                },
                btn: {
                    DEFAULT: '#e62571',
                    border: '#e93f82',
                    hover: '#cd185f',
                    borderHover: '#b41658',
                },
                'text-primary-asphalt': '#0E242B',
                'text-secondary-grey': '#56666B',
                'text-titanium': '#FBFCF9',
                link: '#1776CE',
                'brand-primary-500': '#0A475A',
                'brand-primary-light-30': '#3B6C7B',
                'brand-secondary-500': '#B4E076',
                'brand-secodnary-light-30': '#C3E691',
                'surface-brand': '#E6EDEE',
                'surface-brand-light': '#F5F8F8',
                'surface-brand-super-light': '#FAFBFC',
                'neutral-mid-grey': '#B7BDBF',
                'neutral-dark-grey': '#869195',
                'neutral-light-grey': '#E7E9EA',
                'neutral-soft-grey': '#CFD3D5',
                'status-error-red-100': '#D41818',
                'status-error-red-8': '#FCEDED',
                'status-success-green-100': '#90C652',
                'status-success-text': '#38831E',
                'accent-signal': '#DE3D68',
                'accent-spotlight': '#FFBC00',
            },
            boxShadow: {
                aprLarge: '0px 8px 16px 0px rgba(40, 70, 74, 0.04)',
            },
            maxWidth: {
                aprTextContainer: '808px',
            },
        },
    },
    plugins: [
        plugin(function ({ addComponents, theme }) {
            addComponents({
                // @apply py-8 md:py-16;
                '.apr-section': {
                    paddingTop: theme('spacing.4'),
                    paddingBottom: theme('spacing.4'),
                    '@media (min-width: 768px)': {
                        paddingTop: theme('spacing.12'),
                        paddingBottom: theme('spacing.12'),
                    },
                },
                '.apr-section-title': {
                    fontWeight: '700',
                    textTransform: 'uppercase',

                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                    textAlign: 'left',
                    marginBottom: '8px',
                    '@media (min-width: 768px)': {
                        fontSize: '1.5rem',
                        lineHeight: '1.75rem',
                    },
                    '@media (min-width: 1024px)': {
                        fontSize: '3rem',
                        lineHeight: '3.25rem',
                        marginBottom: '16px',
                    },
                },
                '.apr-card': {
                    borderRadius: '8px',
                    border: '1px solid #CFD3D5',
                    background: '#FFF',
                    boxShadow: '0px 8px 16px 0px rgba(40, 70, 74, 0.04)',
                },
            });
        }),
    ],
};
